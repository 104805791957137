import FeaturesComponent from '../components/FeaturesComponent'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'
import {Helmet} from 'react-helmet'
import HeroAreaComponent from '../components/HeroAreaComponent'
import React from 'react'
import TechComponent from '../components/TechComponent'

const CaroPersonalPage = () => {

  const navItems = [
    {
      'title':'Home',
      'url':'#hero-area'
    },
    {
      'title':'Demo',
      'url':'#demo'
    },
    {
      'title':'Tech',
      'url':'#tech'
    }
  ]


  const tabItems = [
    {
      "id":"1",
      "title":"Personal",
      "url":"personal",
      "position":"1"
    },
    {
      "id":"1",
      "title":"One Page",
      "url":"onepage",
      "position":"1"
    },
    /*
    {
      'title':'Layout',
      'url':'layout'
    },


    {
      'title':'HTML',
      'url':'html'
    },
    {
      'title':'Dashboard',
      'url':'dashboard'
    },
    */
  ]

  const dataHero = {
    'title': 'Get Your Personal Portfolio',
    'subtitle' : 'We always present the best html templates for your personal portfolio. We will support professionalism in your career development, through the templates we created.',
    'btn_url': [
      {
        'title':'Enter Portfolio',
        'url':'#demo',
        'target':''
      },
    ],
  }

  /** portofolio list */
  const portofolioItems = [
    {
      'title':'Ladyleet',
      'image_url':'./assets/caro/images/demos/ladyleet.jpg',
      'image_alt':'Ladyleet',
      'code_url':'html/ladyleet-personal/intro.html',
      'code_langs': [
        {
          'code_title':'HTML',
          'code_tooltip':'HTML',
          'code_url':'html/ladyleet-personal/intro.html',
        },
      ]
    },
    {
      'title':'Jaro',
      'image_url':'./assets/caro/images/demos/jaro.jpg',
      'image_alt':'Jaro',
      'code_url':'html/jaro-personal/intro.html',
      'code_langs': [
        {
          'code_title':'HTML',
          'code_tooltip':'HTML',
          'code_url':'html/jaro-personal/intro.html',
        },
      ],
      '_hide':[
        {
          '_author':'marketify'
        }
      ]
    },

  ]


  return (
    <div className="theme-caro">
      <Helmet>
        <title>Quzza Studio</title>
        <meta name="description" content="Personal Template" />
        { /* Plugin-css */}
        <link rel="stylesheet" href="./assets/caro/css/tiny-slider.css"/>
        <link rel="stylesheet" href="./assets/caro/css/glightbox.min.css"/>
        { /* Page-css */}
        <link rel="stylesheet" href="./assets/caro/css/main.style.min.css"/>
        {/**/}
      </Helmet>

      {/** Loader-Start *-/}
      <div className="quzza-block preloader">
        <div className="preloader-inner">
          <div className="preloader-icon"><span></span><span></span></div>
        </div>
      </div>
      {/**/}

      {/** Header-Start */}
      <HeaderComponent navItems={navItems} />
      <HeroAreaComponent dataHero={dataHero} />

      <FeaturesComponent tabItems={tabItems} portofolioItems={portofolioItems} />
      {/*/}
      <TechComponent />
      {/*/}
      <FooterComponent />
    </div>
  )
}
export default CaroPersonalPage
