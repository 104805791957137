import React from 'react'

const FooterComponent = () => {

  /** Menu untuk link footer */
  const menuFooterItems = [
    {
      'title':'Company',
      'menus': [
        {
          'title':'Blog',
          'url':'https://quzzastudio.vercel.app/blog/',
        },
        {
          'title':'About Us',
          'url':'https://quzzastudio.vercel.app/about/',
        },
        {
          'title':'Services',
          'url':'https://quzzastudio.vercel.app/services/',
        },
        {
          'title':'Portfolio',
          'url':'https://quzzastudio.vercel.app/portfolio/',
        },
      ]
    },
    {
      'title':'Services',
      'menus': [
        {
          'title':'UI/UX Design',
          'url':'https://quzzastudio.vercel.app/ui-ux-design/',
        },
        {
          'title':'Web Development',
          'url':'https://quzzastudio.vercel.app/web-development/',
        },
      ]
    }
  ]

  return (
    <>
    <footer class="footer quzza-block py-5">
      <div class="container">
        <div class="row mb-5 justify-content-between">
            <div class="col-lg-5 col-md-6 col-12">
                <div class="f-about single-footer">
                    <div class="logo"><a href="index.html"><img src="./assets/caro/images/logo/white-logo.png" alt="Logo" /></a></div>
                    <p class="h6 fw-normal"></p>
                    <div class="footer-social">
                        <ul class="ps-0">
                            {/*/}
                            <li><a target="_blank" href="https://www.linkedin.com/company//"><i class="fa fa-linkedin"></i></a></li>
                            <li><a target="_blank" href="https://dribbble.com/"><i class="fa fa-dribbble"></i></a></li>
                            <li><a target="_blank" href="https://www.behance.net/"><i class="fa fa-behance"></i></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/company//"><i class="fa fa-linkedin"></i></a></li>
                            <li><a target="_blank" href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                            <li><a target="_blank" href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></li>
                            {/*/}
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <div class="d-flex justify-content-between align-items-start sm-custom-border">
                  { menuFooterItems.map( (menusItem) => (
                    <div class="single-footer f-link">
                      <h3>{menusItem.title}</h3>
                      <ul class="list-unstyled">
                      { menusItem.menus.map((item) => ( <li><a target="_blank" href={item.url}>{item.title}</a></li> ))}
                      </ul>
                    </div>
                  ))}
                </div>
            </div>
        </div>
        <div class="row g-3 border-top pt-3">
          <div class="col-md-6 col-sm-12">
            <span class="text-light">Copyright &#169;2021 Designed by <a class="text-white" href="https://quzzastudio.vercel.app/" rel="nofollow" target="_blank">Quzza Studio</a></span>
          </div>
          <div class="col-md-6 col-sm-12 text-md-end">
            {/*/}
            <a class="text-white" href="licenses.html" rel="nofollow" target="_blank">© licenses</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a class="text-white" href="document.html" rel="nofollow" target="_blank">Document</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a class="text-white" href="changelog.html" rel="nofollow" target="_blank">Changelog</a>
            {/*/}
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}
export default FooterComponent
