import React from 'react'

const FeaturesComponent = (props) => {

  return (
    <div className="quzza-block section features" id="demo">
      <div className="container">

        <div className="row">
          <div className="col-12">
            <div className="tab-head">
              <div className="nav-head">
                <ul className="nav nav-pills justify-content-center list-unstyled" id="pills-tab" role="tablist">
                  {
                    props.tabItems.map( (item) => (
                      <li className="nav-item"><a className="nav-link" href={item.url}>{item.title}</a></li>
                    ))
                  }
                </ul>
              </div>
              <div className="tab-content team quzza-block">
                  <div className="row g-3 row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mt-5 mb-5 row-deck">
                      {
                        props.portofolioItems.map((portofolio) => (
                          <div className="col">
                          <div className="single-team wow fadeInUp" data-wow-delay=".2s">
                              <a className="card-img-top image" href={portofolio.code_url} target="_blank">
                                  <img src={portofolio.image_url} alt={portofolio.image_alt} />
                              </a>
                              <div className="tech-info px-2 py-3 text-center">
                                  <ul className="list-unstyled d-flex flex-wrap justify-content-center">
                                  {
                                    portofolio.code_langs.map( (codelang) => (
                                      <li><a className="btn btn-sm btn-outline-secondary" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top"
                                        href={codelang.code_url} title={codelang.code_tooltip}>{codelang.code_title}</a></li>
                                    ))
                                  }
                                  </ul>
                                  <h6 className="mb-0 mt-3">{portofolio.title}</h6>
                              </div>
                          </div>
                      </div>
                        ))
                      }
                      {/*/}
                      <div className="col">
                          <div className="single-team wow fadeInUp" data-wow-delay=".2s">
                              <a className="card-img-top image" href="html/ladyleet/demos.html" target="_blank">
                                  <img src="assets/images/demos/ladyleet.jpg" alt="ladyleet" />
                              </a>
                              <div className="tech-info px-2 py-3 text-center">
                                  <ul className="list-unstyled d-flex flex-wrap justify-content-center">
                                      <li><a className="btn btn-sm btn-outline-secondary" target="_blank" href="html/ladyleet/demos.html" data-bs-toggle="tooltip" data-bs-placement="top" title="View demo">HTML</a></li>
                                      <li><a className="btn btn-sm btn-light" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">React</a></li>
                                      <li><a className="btn btn-sm btn-light" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">VueJs</a></li>
                                      <li><a className="btn btn-sm btn-light" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">Angular</a></li>
                                  </ul>
                                  <h6 className="mb-0 mt-3">Ladyleet</h6>
                              </div>
                          </div>
                      </div>
                      {/*/}

                  </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default FeaturesComponent
