import { Route, Switch } from 'react-router-dom'

import CaroMainPage from '../src/theme/caro/pages/CaroMainPage'
import CaroOnePage from '../src/theme/caro/pages/CaroOnePage'
import CaroPersonalPage from '../src/theme/caro/pages/CaroPersonalPage'
import {Helmet} from 'react-helmet'
import Page404Component from '../src/theme/caro/components/Page404Component'
import React from 'react';

function App() {
  return (
    <>
      <Helmet>
        <title>Caro by Quzza Studio</title>
        <meta name="description" content="Helmet application" />
        <meta name="keyword" content="CARO, Bootstrap 5, ReactJs, Angular, VueJs, Admin Dashboard, Admin Theme"></meta>
      </Helmet>

      <Switch>
        <Route path="/" exact component={CaroPersonalPage} />
        <Route path="/personal" component={CaroPersonalPage} />
        <Route path="/main" component={CaroMainPage} />
        <Route path="/onepage" component={CaroOnePage} />

        <Route path='*' exact={true} component={Page404Component} />
      </Switch>
    </>
  );
}

export default App;
