import React from 'react';

const TechComponent = () => {
  return (
    <>
    {/** Technology-Start */}
    <div className="section quzza-block tech-tab" id="tech">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-title quzza-block">
                        <span className="wow fadeInDown" data-wow-delay=".2s">3 Top Frameworks</span>
                        <h2 className="wow fadeInUp" data-wow-delay=".4s">CARO BEST Features.</h2>
                        <p className="wow fadeInUp lead text-muted" data-wow-delay=".6s">CARO is a library of widgets created with many third-party libraries you already know and love. Everything is seamlessly integrated into CARO, creating a new standard in dashboard design.</p>
                    </div>
                </div>
            </div>
            <div className="row g-3">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <div className="nav flex-column nav-pills me-lg-4" id="quzza-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link text-start ps-4 mb-1 active" id="quzza-pills-html-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-html" type="button" role="tab" aria-controls="quzza-pills-html" aria-selected="true">HTML Version</button>
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-react-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-react" type="button" role="tab" aria-controls="quzza-pills-react" aria-selected="false">React Version</button>
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-vuejs-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-vuejs" type="button" role="tab" aria-controls="quzza-pills-vuejs" aria-selected="false">VueJS Version</button>
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-angular-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-angular" type="button" role="tab" aria-controls="quzza-pills-angular" aria-selected="false">Angular Version</button>
                        { /*/}
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-laravel-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-laravel" type="button" role="tab" aria-controls="quzza-pills-laravel" aria-selected="false">Laravel Version</button>
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-laravelvue-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-laravelvue" type="button" role="tab" aria-controls="quzza-pills-laravelvue" aria-selected="false">Laravel+VueJS</button>
                        <button className="nav-link text-start ps-4 mb-1" id="quzza-pills-asp-tab" data-bs-toggle="pill" data-bs-target="#quzza-pills-asp" type="button" role="tab" aria-controls="quzza-pills-asp" aria-selected="false">Asp .Net Version</button>
                        { /*/}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <div className="tab-content ps-xl-5 ps-0" id="quzza-pills-tabContent">
                        <div className="tab-pane fade show active" id="quzza-pills-html" role="tabpanel" aria-labelledby="quzza-pills-html-tab">
                            <div className="row g-2 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/npm.png" alt="#" />
                                        <span>NPM</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/CSS3.png" alt="#" />
                                        <span>Css3</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Datatables.png" alt="#" />
                                        <span>Data Table</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/list.svg" alt="#" />
                                        <span>Todo List</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/text.svg" alt="#" />
                                        <span>Google Font</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/chat.svg" alt="#" />
                                        <span>Chat</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Support.png" alt="#" />
                                        <span>Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/contact.svg" alt="#" />
                                        <span>Contact</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/plugin.svg" alt="#" />
                                        <span>Reday Plugin</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/customer.svg" alt="#" />
                                        <span>User App</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="quzza-pills-angular" role="tabpanel" aria-labelledby="quzza-pills-angular-tab">
                            <h1>Coming Soon</h1>
                            {/*/}
                            <div className="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Angular.png" alt="#" />
                                        <span>Angular 12</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Ng_Bootstrap.png" alt="#" />
                                        <span>Ng Bootstrap</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/npm.png" alt="#" />
                                        <span>NPM</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/list.svg" alt="#" />
                                        <span>Task Manager</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/text.svg" alt="#" />
                                        <span>Google Font</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/chat.svg" alt="#" />
                                        <span>Chat</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/RxJS.png" alt="#" />
                                        <span>RxJS</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Angular_Forms.png" alt="#" />
                                        <span>Forms</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Angular_Router.png" alt="#" />
                                        <span>Router</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/NGRX.png" alt="#" />
                                        <span>NGRX</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/spa.svg" alt="#" />
                                        <span>SPA</span>
                                    </div>
                                </div>
                            </div>
                            {/*/}
                        </div>
                        <div className="tab-pane fade" id="quzza-pills-react" role="tabpanel" aria-labelledby="quzza-pills-react-tab">
                            <div className="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/React.png" alt="#" />
                                        <span>React</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/React_BS.png" alt="#" />
                                        <span>React Bootstrap</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/npm.png" alt="#" />
                                        <span>NPM</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/React_Router.png" alt="#" />
                                        <span>React Router</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/list.svg" alt="#" />
                                        <span>ToDo List</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/text.svg" alt="#" />
                                        <span>Google Font</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/chat.svg" alt="#" />
                                        <span>Chat</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Redux.png" alt="#" />
                                        <span>Redux</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Support.png" alt="#" />
                                        <span>Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/customer.svg" alt="#" />
                                        <span>Accessibility</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/spa.svg" alt="#" />
                                        <span>SPA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="quzza-pills-vuejs" role="tabpanel" aria-labelledby="quzza-pills-vuejs-tab">
                            <div className="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/VueJS.png" alt="#" />
                                        <span>VueJS</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Vue_Bootstrap.png" alt="#" />
                                        <span>Vue Bootstrap</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/npm.png" alt="#" />
                                        <span>NPM</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Vue_Router.png" alt="#" />
                                        <span>Vue Router</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Vue_cli.png" alt="#" />
                                        <span>Vue Cli</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/list.svg" alt="#" />
                                        <span>ToDo List</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/text.svg" alt="#" />
                                        <span>Google Font</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/chat.svg" alt="#" />
                                        <span>Chat</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Support.png" alt="#" />
                                        <span>Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/customer.svg" alt="#" />
                                        <span>Accessibility</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/spa.svg" alt="#" />
                                        <span>SPA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="quzza-pills-laravel" role="tabpanel" aria-labelledby="quzza-pills-laravel-tab">
                            <h1>Coming Soon</h1>
                            {/*/}
                            <div className="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Laravel.png" alt="#" />
                                        <span>Laravel</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Mix.png" alt="#" />
                                        <span>Mix</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/contact.svg" alt="#" />
                                        <span>Boilerplate</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Skeleton.svg" alt="#" />
                                        <span>Skeleton</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/access-management.svg" alt="#" />
                                        <span>Access</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/user-managment.svg" alt="#" />
                                        <span>User Managment</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/role-managment.svg" alt="#" />
                                        <span>Role Managment</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/authorization.svg" alt="#" />
                                        <span>Authorization</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cruds.svg" alt="#" />
                                        <span>Cruds</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/eCommerce.svg" alt="#" />
                                        <span>eCommerce</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                            </div>
                            {/*/}
                        </div>
                        <div className="tab-pane fade" id="quzza-pills-laravelvue" role="tabpanel" aria-labelledby="quzza-pills-laravelvue-tab">
                            <h1>Coming Soon</h1>
                            {/*/}
                            <div className="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Laravel.png" alt="#" />
                                        <span>Laravel</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/VueJS.png" alt="#" />
                                        <span>VueJS</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Bootstrap.png" alt="#" />
                                        <span>Bootstrap 5</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Vue_Bootstrap.png" alt="#" />
                                        <span>Vue Bootstrap</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Mix.png" alt="#" />
                                        <span>Mix</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Skeleton.svg" alt="#" />
                                        <span>Skeleton</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Sass.png" alt="#" />
                                        <span>Sass</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/FullCalendar.png" alt="#" />
                                        <span>FullCalendar</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Apex_Charts.png" alt="#" />
                                        <span>Apex Charts</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/direction-rtl.svg" alt="#" />
                                        <span>Rtl Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/list.svg" alt="#" />
                                        <span>ToDo List</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/text.svg" alt="#" />
                                        <span>Google Font</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/chat.svg" alt="#" />
                                        <span>Chat</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/Support.png" alt="#" />
                                        <span>Support</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/customer.svg" alt="#" />
                                        <span>Accessibility</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/paint-roller.svg" alt="#" />
                                        <span>Customize Color</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/cross-platform.jpg" alt="#" />
                                        <span>Cross-platform</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="tech-item d-flex align-items-center justify-content-center flex-column">
                                        <img src="./assets/caro/images/tech-icon/rwd.png" alt="#" />
                                        <span>RWD</span>
                                    </div>
                                </div>
                            </div>
                            {/*/}
                        </div>
                        <div className="tab-pane fade justify-content-center align-content-center d-flex align-items-center" id="quzza-pills-asp" role="tabpanel" aria-labelledby="quzza-pills-asp-tab">
                            <h1>Coming Soon</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
export default TechComponent
