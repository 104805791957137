import React from 'react'

const HeroAreaComponent = (data) => {

  const {title, subtitle, btn_url } = data.dataHero
  return (
    <>
    {/** Hero-area-Start */}
    <div className="quzza-block hero-area" id="hero-area">
      <div className="hero-inner quzza-block d-flex justify-content-center  align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 co-12">
              <div className="hero-text">
                {/*/}
                <h5 className="wow fadeInUp" data-wow-delay=".2s">welcome</h5>
                {/*/}
                <h1 className="wow fadeInUp" data-wow-delay=".4s">{title}</h1>
                <p className="wow fadeInUp" data-wow-delay=".6s">{subtitle}</p>
                <div className="button wow fadeInUp" data-wow-delay=".7s">
                  {btn_url.map((btn) => (
                    <a href={btn.url} target={btn.target} className="btn btn-animation text-uppercase my-1">{btn.title}</a>
                  ))}
                </div>
                <div className="tech-icon mt-4 wow fadeInUp" data-wow-delay=".7s">
                  <ul className="d-flex">
                    <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Bootstrap5"><img src="./assets/caro/images/tech-icon/bootstrap.svg" className="img-fluid" alt="bootstrap" /></li>
                    <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Html5"><img src="./assets/caro/images/tech-icon/icon-html5.svg" className="img-fluid" alt="html5" /></li>
                    <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="React"><img src="./assets/caro/images/tech-icon/icon-react.svg" className="img-fluid" alt="react" /></li>
                    <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="VueJs"><img src="./assets/caro/images/tech-icon/icon-Vuejs.svg" className="img-fluid" alt="vuejs" /></li>
                    <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Angular"><img src="./assets/caro/images/tech-icon/icon-angular.svg" className="img-fluid" alt="angular" /></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <img className="img-fluid" src="./assets/caro/images/hero/laptop.png" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default HeroAreaComponent
