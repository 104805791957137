import {Helmet} from 'react-helmet'
import React from 'react'

const CaroMainPage = () => {
  return (
    <div>
      <Helmet>
        <title>Layout by Quzza Studio</title>
        <meta name="description" content="Layout" />

        { /* Plugin-css */}
        <link rel="stylesheet" href="assets/css/tiny-slider.css"/>
        <link rel="stylesheet" href="assets/css/glightbox.min.css"/>
        { /* Page-css */}
        <link rel="stylesheet" href="assets/css/main.style.min.css"/>
        <link rel="stylesheet" href="assets/css/caro.style.css"/>
      </Helmet>
      Caro Main
    </div>
  )
}
export default CaroMainPage
