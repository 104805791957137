import {Link} from 'react-router-dom'
import React from 'react'

const HeaderComponent = ({navItems}) => {

  return (
    <header className="quzza-block header">
      <div className="navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand logo" to="/">
                  <img className="primary-logo" src="./assets/caro/images/logo/white-logo.png" alt="Logo" />
                  <img className="alt-logo" src="./assets/caro/images/logo/logo.png" alt="Logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                  <ul id="nav" className="navbar-nav ms-auto d-inline-flex">
                    {
                    navItems.map( (item) => (
                      <li className="nav-item"><a className="page-scroll active" href={item.url}>{item.title}</a></li>
                    ))
                    }
                    <li className="nav-item">
                        <a href="https://themeforest.net/user/quzzastudio" target="_blank" className="btn btn-animation">DOWNLOAD NOW<i className="fa fa-download ms-2"></i></a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default HeaderComponent
