import FeaturesComponent from '../components/FeaturesComponent'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'
import {Helmet} from 'react-helmet'
import HeroAreaComponent from '../components/HeroAreaComponent'
import React from 'react'
import TechComponent from '../components/TechComponent'

const CaroOnePage = () => {

  const navItems = [
    {
      'title':'Home',
      'url':'#hero-area'
    },
    {
      'title':'Demo',
      'url':'#demo'
    },
    {
      'title':'Tech',
      'url':'#tech'
    }
  ]


  const tabItems = [
    {
      'title':'Personal',
      'url':'personal'
    },
    {
      'title':'One Page',
      'url':'onepage'
    },
    {
      'title':'Layout',
      'url':'layout'
    },
    {
      'title':'HTML',
      'url':'html'
    },
    {
      'title':'Dashboard',
      'url':'dashboard'
    },
  ]

  const dataHero = {
    'title': 'Best Ever Dashboard template',
    'subtitle' : 'Build your web project with powerful CARO admin dashboard template that is tailored to fit every company\'s needs. Plus, we support all the major front-end and back-end technologies.',
    'btn_url': [
      {
        'title':'Enter Portfolio',
        'url':'onepage#demo',
        'target':''
      },
    ],
  }

  /** portofolio list */
  const portofolioItems = [
    {
      'title':'Ladyleet',
      'image_url':'./assets/caro/images/demos/ladyleet.jpg',
      'image_alt':'Ladyleet',
      'code_url':'',
      'code_langs': [
        {
          'code_title':'HTML',
          'code_tooltip':'HTML',
          'code_url':'./assets/caro/images/demos/ladyleet.jpg',
        },
        {
          'code_title':'React',
          'code_tooltip':'Coming Soon',
          'code_url':'',
        },
        {
          'code_title':'VueJs',
          'code_tooltip':'Coming Soon',
          'code_url':'',
        },
        {
          'code_title':'Angular',
          'code_tooltip':'Coming Soon',
          'code_url':'',
        },
      ]
    }
  ]


  return (
    <div className="theme-indigo">
      <Helmet>
        <title>Quzza Studio</title>
        <meta name="description" content="Personal Template" />
        { /* Plugin-css */}
        <link rel="stylesheet" href="./assets/caro/css/tiny-slider.css"/>
        <link rel="stylesheet" href="./assets/caro/css/glightbox.min.css"/>
        { /* Page-css */}
        <link rel="stylesheet" href="./assets/caro/css/main.style.min.css"/>
        {/**/}
      </Helmet>

      {/** Loader-Start *-/}
      <div className="quzza-block preloader">
        <div className="preloader-inner">
          <div className="preloader-icon"><span></span><span></span></div>
        </div>
      </div>
      {/**/}

      {/** Header-Start */}
      <HeaderComponent navItems={navItems} />
      <HeroAreaComponent dataHero={dataHero} />

      <FeaturesComponent tabItems={tabItems} portofolioItems={portofolioItems} />

      <TechComponent />
      <FooterComponent />
    </div>
  )
}

export default CaroOnePage
