import React from 'react'

const Page404Component = () => {
  return (
    <div>
      404 Page not found
    </div>
  )
}
export default Page404Component
